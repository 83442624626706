import './App.css';
import logo from "./electron.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Please <a
            className="App-link"
            href="https://drive.google.com/drive/u/0/folders/1ruSwGMWvAyUuhaiAmKdAweagetjY5Cr3"
            target="_blank"
            rel="noopener noreferrer"
          >
            download
          </a> and use LuxTrack desktop app.
        </p>
        <p>
          Installation instructions for <a
            className="App-link"
            href="https://www.loom.com/share/ed0dd37fb2d44dd8900d93de0fb2dd1a"
            target="_blank"
            rel="noopener noreferrer"
          >
            MacOS
          </a>, <a
            className="App-link"
            href="https://www.loom.com/share/23ce2beff9054a9b9b299039ce97e4ac"
            target="_blank"
            rel="noopener noreferrer"
          >
            Windows x64
          </a>
        </p>
        <p>
          Visit web version without Time tracking feature <a
            className="App-link"
            href="http://track.luxtech.global/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
